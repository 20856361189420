<template>
	<div class="table-head-sticky w-100">
		<div class="bt-table ranking-table listing-table">
			<div v-if="loading" class="mx-20 my-40 text-center">
				<h2 class="fw-600">Loading...</h2>
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
			<v-simple-table v-else width="100%" class="detail-table normal-table table-head-sticky">
				<thead>
					<tr>
						<template v-for="(thead, thead_index) in tableHeading">
							<th
								:key="`${thead.title}${thead_index}`"
								class="simple-table-th"
								:style="`width: ${thead.width}px;max-width: ${thead.width}px;`"
							>
								<template v-if="format_Date(thead.title)">
									{{ format_Date(thead.title) }}
								</template>
								<template v-else>
									{{ thead.title }}
								</template>
							</th>
						</template>
					</tr>
				</thead>
				<tbody>
					<template v-for="(tbody, tbody_index) in tableData">
						<tr :key="'row-data' + tbody_index">
							<template v-for="(thead, thead_index) in tableHeading">
								<td
									v-if="thead.position == 'sticky'"
									:key="'thead_' + thead_index"
									class="td_column cursor-default simple-table-td border-top-light-grey text-truncate"
									:style="`width: ${thead.width}px;max-width: ${thead.width}px;`"
								>
									<template v-if="thead.key == 'target_url'">
										<v-icon @click="openInNewTab(tbody[thead.key])" color="blue darken-4"
											>mdi-link-box-outline</v-icon
										>
									</template>
									{{ tbody[thead.key] }}
								</td>
								<td
									v-else
									:key="'thead_s' + thead_index"
									class="td_column cursor-default simple-table-td border-top-light-grey text-truncate"
								>
									<v-chip outlined :color="tbody[thead.key].diffrence >= 0 ? 'green' : 'red'">
										<p class="mb-0 mt-1">{{ tbody[thead.key].value }}</p>
										<p class="mb-0 ml-2">
											<template v-if="tbody[thead.key].diffrence > 0">
												<v-icon color="green">mdi-menu-up</v-icon>
											</template>
											<template v-if="tbody[thead.key].diffrence == 0">
												<div class="pt-1 pr-1">-</div>
											</template>
											<template v-if="tbody[thead.key].diffrence < 0">
												<v-icon color="red">mdi-menu-down</v-icon>
											</template>
										</p>
										<p class="mb-0 mt-1" style="font-size: 0.8rem">{{ tbody[thead.key].diffrence }}</p>
									</v-chip>
								</td>
							</template>
						</tr>
					</template>
				</tbody>
			</v-simple-table>
		</div>
	</div>
</template>
<script>
import MomentJS from "moment-timezone";
export default {
	name: "table-layout",
	data() {
		return {
			tableHeading: [],
			tableData: [],
		};
	},
	props: {
		thead: {
			type: Array,
			default: () => {
				return [];
			},
		},
		tbody: {
			type: Array,
			default: () => {
				return [];
			},
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		thead: {
			handler(newValue) {
				this.tableHeading = newValue;
			},
			deep: true,
		},
		tbody: {
			handler(newValue) {
				this.tableData = newValue;
			},
			deep: true,
		},
	},
	methods: {
		format_Date(heading) {
			if (MomentJS(heading)._isValid) {
				return MomentJS(heading).format("DD/MM/YYYY");
			} else {
				return heading;
			}
		},
		openInNewTab(url) {
			if (url) {
				window.open(url, "_blank");
			}
		},
	},
};
</script>
<style lang="scss">
.bt-table.ranking-table table tbody tr td {
	vertical-align: middle;
}
.bt-table.ranking-table table tbody tr td:nth-child(1) {
	position: sticky;
	left: 0;
	background: inherit;
	z-index: 1;
}
.bt-table.ranking-table table thead tr th:nth-child(1) {
	position: sticky;
	left: 0;
	top: 0;
	z-index: 10;
}
.bt-table.ranking-table table tbody tr:nth-child(odd) {
	background: #e7f4ff;
}
.bt-table.ranking-table table tbody tr:nth-child(even) {
	background: #ffffff;
}
</style>
