<template>
	<div style="width: 100%">
		<div class="pa-2">
			<v-row>
				<v-col md="3">
					<div
						class="card-3 pa-4 px-6 mb-2 elevation-3 rounded-lg d-flex pa-6 d-flex justify-space-around"
						style="height: 115px"
					>
						<div>
							<div class="font-level-4-bold white--text" style="font-size: 20px">
								{{ topRankingData?.top_1 }}
							</div>
							<div class="font-level-2-bold white--text mt-2">Keyword In Top 1</div>
						</div>
						<div class="ml-3">
							<v-progress-circular
								rotate="360"
								size="80"
								width="6"
								:value="getKeywordPcnt(topRankingData?.top_1)"
								color="white"
							>
								<span class="font-level-4-bold"> {{ getKeywordPcnt(topRankingData?.top_1) }} </span>
							</v-progress-circular>
						</div>
					</div>
				</v-col>
				<v-col md="3">
					<div
						class="pa-4 px-6 mb-2 card-7 elevation-3 rounded-lg d-flex pa-6 d-flex justify-space-around"
						style="height: 115px"
					>
						<div>
							<div class="font-level-4-bold white--text" style="font-size: 20px">
								{{ topRankingData?.top_3 }}
							</div>
							<div class="font-level-2-bold white--text mt-2">Keyword In Top 3</div>
						</div>
						<div class="ml-3">
							<v-progress-circular
								rotate="360"
								size="80"
								width="6"
								:value="getKeywordPcnt(topRankingData?.top_3)"
								color="white"
							>
								<span class="font-level-4-bold"> {{ getKeywordPcnt(topRankingData?.top_3) }} </span>
							</v-progress-circular>
						</div>
					</div>
				</v-col>
				<v-col md="3">
					<div
						class="pa-4 mb-2 px-6 card-1 elevation-3 rounded-lg d-flex pa-6 d-flex justify-space-around"
						style="height: 115px"
					>
						<div>
							<div class="font-level-4-bold white--text" style="font-size: 20px">
								{{ topRankingData?.top_5 }}
							</div>
							<div class="font-level-2-bold white--text mt-2">Keyword In Top 5</div>
						</div>
						<div class="ml-3">
							<v-progress-circular
								rotate="360"
								size="80"
								width="6"
								:value="getKeywordPcnt(topRankingData?.top_5)"
								color="white"
							>
								<span class="font-level-4-bold"> {{ getKeywordPcnt(topRankingData?.top_5) }} </span>
							</v-progress-circular>
						</div>
					</div>
				</v-col>
				<v-col md="3">
					<div
						class="pa-4 px-6 mb-2 card-2 elevation-3 rounded-lg d-flex pa-6 d-flex justify-space-around"
						style="height: 115px"
					>
						<div>
							<div class="font-level-4-bold white--text" style="font-size: 20px">
								{{ topRankingData?.top_10 }}
							</div>
							<div class="font-level-2-bold white--text mt-2">Keyword In Top 10</div>
						</div>
						<div class="ml-3">
							<v-progress-circular
								rotate="360"
								size="80"
								width="6"
								:value="getKeywordPcnt(topRankingData?.top_10)"
								color="white"
							>
								<span class="font-level-4-bold"> {{ getKeywordPcnt(topRankingData?.top_10) }} </span>
							</v-progress-circular>
						</div>
					</div>
				</v-col>
				<v-col md="3">
					<div
						class="pa-4 px-6 mb-2 card-4 elevation-3 rounded-lg d-flex pa-6 d-flex justify-space-around"
						style="height: 115px"
					>
						<div>
							<div class="font-level-4-bold white--text" style="font-size: 20px">
								{{ topRankingData?.top_20 }}
							</div>
							<div class="font-level-2-bold white--text mt-2">Keyword In Top 20</div>
						</div>
						<div class="ml-3">
							<v-progress-circular
								rotate="360"
								size="80"
								width="6"
								:value="getKeywordPcnt(topRankingData?.top_20)"
								color="white"
							>
								<span class="font-level-4-bold"> {{ getKeywordPcnt(topRankingData?.top_20) }} </span>
							</v-progress-circular>
						</div>
					</div>
				</v-col>
				<v-col md="3">
					<div
						class="pa-4 px-6 mb-2 card-5 elevation-3 rounded-lg d-flex pa-6 d-flex justify-space-around"
						style="height: 115px"
					>
						<div>
							<div class="font-level-4-bold white--text" style="font-size: 20px">
								{{ topRankingData?.top_50 }}
							</div>
							<div class="font-level-2-bold white--text mt-2">Keyword In Top 50</div>
						</div>
						<div class="ml-3">
							<v-progress-circular
								rotate="360"
								size="80"
								width="6"
								:value="getKeywordPcnt(topRankingData?.top_50)"
								color="white"
							>
								<span class="font-level-4-bold"> {{ getKeywordPcnt(topRankingData?.top_50) }} </span>
							</v-progress-circular>
						</div>
					</div>
				</v-col>
				<v-col md="3">
					<div
						class="pa-4 px-6 mb-2 card-6 elevation-3 rounded-lg d-flex pa-6 d-flex justify-space-around"
						style="height: 115px"
					>
						<div>
							<div class="font-level-4-bold white--text" style="font-size: 20px">
								{{ topRankingData?.top_100 }}
							</div>
							<div class="font-level-2-bold white--text mt-2">Keyword In Top 100</div>
						</div>
						<div class="ml-3">
							<v-progress-circular
								rotate="360"
								size="80"
								width="6"
								:value="getKeywordPcnt(topRankingData?.top_100)"
								color="white"
							>
								<span class="font-level-4-bold"> {{ getKeywordPcnt(topRankingData?.top_100) }} </span>
							</v-progress-circular>
						</div>
					</div>
				</v-col>
				<v-col md="3">
					<div
						class="pa-4 px-6 mb-2 card-8 d-flex elevation-3 rounded-lg pa-6 d-flex justify-space-around"
						style="height: 115px"
					>
						<div>
							<div class="font-level-4-bold white--text" style="font-size: 20px">
								{{ topRankingData?.keyword_SERP }}
							</div>
							<div class="font-level-2-bold white--text mt-2">Keyword Not In SERP</div>
						</div>
						<div class="ml-3">
							<v-progress-circular
								rotate="360"
								size="80"
								width="6"
								:value="getKeywordPcnt(topRankingData?.keyword_SERP)"
								color="white"
							>
								<span class="font-level-4-bold">
									{{ getKeywordPcnt(topRankingData?.keyword_SERP) }}
								</span>
							</v-progress-circular>
						</div>
					</div>
				</v-col>
			</v-row>
		</div>
		<div class="pa-2">
			<div class="font-level-4-bold">Avg. Position Filter</div>
			<div class="d-flex flex-column">
				<div class="d-flex">
					<div
						class="d-flex justify-space-between v-expansion-panel-hover tab p-1"
						style="width: 140px; visibility: hidden"
					>
						<div class="pa-1 d-flex justify-center" style="width: 100%"></div>
					</div>
					<div class="d-flex mx-2">
						<template v-for="(item, index) in filter">
							<div :key="index" style="width: 125px">
								<div class="d-flex justify-center" style="width: 100%; padding-bottom: 2px">
									<div class="text-center">
										{{ getKeywordPcnt(seoData && seoData.current_rank ? seoData.current_rank[item.key] : 0) }}
									</div>
								</div>
							</div>
						</template>
					</div>
				</div>
				<div class="d-flex">
					<div
						class="d-flex justify-space-between v-expansion-panel-hover tab p-1"
						style="width: 140px; visibility: hidden"
					>
						<div class="pa-1 d-flex justify-center" style="width: 100%"></div>
					</div>
					<div class="d-flex mx-2" style="border: 0.2rem solid #d6d8e3; border-radius: 1px">
						<template v-for="(item, index) in filter">
							<div
								:key="index"
								@click="changeActiveTab(index + 1)"
								class="d-flex justify-space-between v-expansion-panel-hover tab p-1"
								style="width: 130px; position: relative"
								:class="[activeTab == index + 1 ? 'active-tab' : ' tab-hover inactive-tab']"
							>
								<div class="pa-1 d-flex justify-center" style="width: 100%">
									<div class="font-level-2-bold text-center">{{ item.text }}</div>
								</div>
							</div>
						</template>
					</div>
				</div>
				<div style="width: 1145px">
					<div class="d-flex p-2">
						<div class="mr-3">
							<div style="width: 125px; position: relative" class="font-level-3-bold">Current Status</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.current_rank?.top_1 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.current_rank?.top_3 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.current_rank?.top_5 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.current_rank?.top_10 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.current_rank?.top_20 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.current_rank?.top_50 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.current_rank?.keyword_SERP }}</div>
							</div>
						</div>
					</div>
					<div style="border-radius: 0.4rem" class="d-flex grey lighten-4 p-2">
						<div class="mr-3">
							<div style="width: 125px; position: relative" class="font-level-3-bold">To Client</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.to_customer?.top_1 || 0 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.to_customer?.top_3 || 0 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.to_customer?.top_5 || 0 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.to_customer?.top_10 || 0 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.to_customer?.top_20 || 0 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.to_customer?.top_50 || 0 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">-</div>
							</div>
						</div>
					</div>
					<div class="d-flex p-2">
						<div v-if="userType != 'customer'" class="mr-3">
							<div style="width: 125px; position: relative" class="font-level-3-bold">To Delhi</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.to_delhi?.top_1 || 0 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.to_delhi?.top_3 || 0 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.to_delhi?.top_5 || 0 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.to_delhi?.top_10 || 0 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.to_delhi?.top_20 || 0 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">{{ seoData?.to_delhi?.top_50 || 0 }}</div>
							</div>
						</div>
						<div style="width: 130px !important; position: relative">
							<div class="pa-1 d-flex justify-center" style="width: 100%">
								<div class="text-center">-</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pa-2 d-flex justify-space-between" style="width: 100%">
			<div style="width: 100%" class="detail-card bg-white cursor-pointer">
				<div class="font-level-3-bold">Average Position Graph</div>
				<!-- <div
					class="card--header d-flex align-items-center pa-2 border-bottom"
					v-on:click="Team_tab = !Team_tab"
				>
					<v-spacer></v-spacer>
					<v-icon>{{ Team_tab ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
				</div> -->
				<div class="card--body d-flex align-items-center pa-2" style="width: 100%">
					<div style="width: 100%; height: 100%">
						<apexchart
							ref="apexChartAveragePosition"
							type="area"
							height="350"
							:options="chart_Options"
							:series="series"
						></apexchart>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex justify-space-between mb-4" style="width: 100%">
			<div style="width: 100%" class="detail-card bg-white">
				<div class="font-level-3-bold mb-2">Average Position</div>
				<!-- <div
					class="card--header d-flex align-items-center pa-2 border-bottom"
					v-on:click="Team_tab = !Team_tab"
				>
					<v-spacer></v-spacer>
					<v-icon>{{ Team_tab ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
				</div> -->
				<div
					style="z-index: 2 !important"
					class="pa-2 d-flex align-items-center justify-space-between px-3 border grey lighten-4"
				>
					<div class="d-flex align-center justify-space-between">
						<p class="mb-0 font-level-3-bold mr-3">Filter by</p>
						<v-text-field
							hide-details
							outlined
							v-model="search_query"
							placeholder="Search"
							:disabled="pageLoading"
							:loading="pageLoading"
							clearable
							@input="debounce"
							class="mr-2 bg-white"
						>
						</v-text-field>

						<v-text-field
							hide-details
							outlined
							v-model="search_target_url"
							placeholder="Target URL"
							:disabled="pageLoading"
							:loading="pageLoading"
							clearable
							@input="debounce"
							class="mr-2 bg-white"
						>
						</v-text-field>

						<!-- <v-text-field
							v-model="onlyPositiveNums"
							hide-details
							outlined
							placeholder="Search Vol."
							:disabled="pageLoading"
							:loading="pageLoading"
							clearable
							type="number"
							inputmode="numeric"
							:min="0"
							:max="Number.MAX_SAFE_INTEGER"
							@change="toPositive"
						>
						</v-text-field> -->
					</div>
					<div class="d-flex align-center">
						<div class="d-flex mr-2">
							<div class="mr-2">
								<DatePicker
									hide-details
									pickerType="month"
									v-model="start_month"
									clearable
									hideTopMargin
									:disabled="pageLoading"
									:loading="pageLoading"
									id="start-date"
									placeholder="Start Date"
									customClass="bg-white"
									@change="filterByRange('months')"
									@click:clear="(start_month = null), filterByRange('months')"
								>
								</DatePicker>
							</div>
							<div>
								<DatePicker
									hide-details
									pickerType="month"
									v-model="end_month"
									clearable
									hideTopMargin
									:disabled="pageLoading"
									:loading="pageLoading"
									id="end-date"
									placeholder="End Date"
									customClass="bg-white"
									:min-date="start_month"
									@change="filterByRange('months')"
									@click:clear="(end_month = null), filterByRange('months')"
								>
								</DatePicker>
							</div>
						</div>
						<DateRangePicker
							:id="+new Date()"
							nudgeLeft="78"
							v-model="search_date_range"
							customClass="bg-white"
							@click:clear="(search_date_range = null), filterByRange('range')"
							@change="filterByRange('range')"
						/>
					</div>
				</div>

				<div
					style="z-index: 2 !important"
					v-if="showFilters"
					class="pa-2 d-flex align-items-center justify-space-between px-3"
				></div>

				<div class="card--body pb-2" style="width: 100%" v-show="Team_tab">
					<div
						style="width: 100%; height: 100%; overflow: scroll; z-index: 1 !important"
						class="d-flex table"
					>
						<table-layout eager :thead="tHead" :tbody="tbody" :loading="pageLoading" />
					</div>
				</div>

				<!-- <Dialog></Dialog> -->
			</div>
		</div>
	</div>
</template>
<script>
// import ShowValue from "@/view/components/ShowValue.vue";
import { QUERY, GET } from "@/core/services/store/request.module";
import TableLayout from "./table-layout.vue";
import DateRangePicker from "@/view/components/DateRangePickerV2.vue";
import DatePicker from "@/view/components/DatePicker.vue";
import { cloneDeep } from "lodash";

export default {
	name: "Domain-Ranking-Tab",
	props: {
		seoId: {
			type: [Number, String],
			default: null,
		},
		projectId: {
			type: [Number, String],
			default: null,
		},
		userType: {
			type: String,
			default: "seo",
		},
		detail: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	components: {
		// ShowValue,
		DateRangePicker,
		TableLayout,
		DatePicker,
	},
	data() {
		return {
			pageLoading: false,
			about_tab: true,
			Team_tab: true,
			activeTab: 1,
			thead: [],
			tbody: [],
			timeout: null,
			search_query: null,
			search_target_url: null,
			search_date_range: [],
			chart_category: [],
			chart_series: [],
			static_tab: true,

			seoData: {},
			seoTotal: {
				current_rank: 0,
				to_delhi: 0,
				to_customer: 0,
			},

			filter: [
				{
					text: "Top 1",
					value: 0,
					percentage: "10%",
					key: "top_1",
				},
				{
					text: "Top 3",
					value: 1,
					percentage: "10%",
					key: "top_3",
				},
				{
					text: "Top 5",
					value: 3,
					percentage: "30%",
					key: "top_5",
				},
				{
					text: "Top 10",
					value: 3,
					percentage: "30%",
					key: "top_10",
				},
				{
					text: "Top 20",
					value: 3,
					percentage: "30%",
					key: "top_20",
				},
				{
					text: "Top 50",
					value: 3,
					percentage: "30%",
					key: "top_50",
				},
				{
					text: "Not in SERP",
					value: 3,
					percentage: "30%",
					key: "keyword_SERP",
				},
			],
			series: [
				{
					name: "Average Position",
					data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
				},
			],
			chartOptions: {
				chart: {
					height: 350,
					type: "area",
					zoom: {
						enabled: false,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				fill: {
					type: "gradient",
					gradient: {
						shadeIntensity: 1,
						opacityFrom: 0.5,
						opacityTo: 0.7,
						stops: [0, 90, 100],
					},
				},
				// title: {
				// 	text: "Product Trends by Month",
				// 	align: "left",
				// },
				grid: {
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				xaxis: {
					type: "Date",
					categories: [
						"July 1 2023",
						"July 2 2023",
						"July 3 2023",
						"July 4 2023",
						"July 5 2023",
						"July 6 2023",
						"July 7 2023",
						"July 8 2023",
						"July 9 2023",
					],
					labels: {
						show: true,
						rotate: -45,
						formatter: function (value /*,timestamp , opts */) {
							return value;
						},
					},
					title: {
						text: "Date",
					},
				},
				yaxis: {
					reversed: true,
					title: {
						text: "Average Position",
					},
				},
			},
			categories: [
				"July 1 2023",
				"July 2 2023",
				"July 3 2023",
				"July 4 2023",
				"July 5 2023",
				"July 6 2023",
				"July 7 2023",
				"July 8 2023",
				"July 9 2023",
			],
			showFilters: false,
			search_vol: null,
			topRankingData: {},
			start_month: null,
			end_month: null,
			timer: null,
		};
	},
	methods: {
		getKeywordPcnt(rank) {
			if (this.detail && (this.detail.keyword_free || this.detail.keyword_monthly)) {
				let freeKeyWord = this.detail.keyword_free ? Number(this.detail.keyword_free) : 0;
				let keyWord = this.detail.keyword_monthly ? Number(this.detail.keyword_monthly) : 0;
				let totalKeyword = Number(freeKeyWord) + Number(keyWord);
				let rankPcnt = (Number(rank) * 100) / totalKeyword;
				return rankPcnt ? `${rankPcnt.toFixed(2)}%` : `0%`;
			}
			return "0%";
		},
		changeActiveTab(activeTab) {
			this.activeTab = activeTab;
		},
		allowedDatesOfRangePickerInFilters(val) {
			return val <= new Date().toISOString().substr(0, 10);
		},
		toPositive(value) {
			if (+value > 0) {
				this.search_vol = +value;
			} else if (value.includes("-") || +value < 0) {
				this.search_vol = +value.replace("-", "");
			}
		},
		filterByRange(type) {
			if (type == "range" && this.search_date_range.length != 1) {
				this.getRankingList();
				this.start_month = null;
				this.end_month = null;
			} else if (type == "months") {
				if (
					(this.start_month && this.end_month) ||
					(Boolean(this.start_month) == false && Boolean(this.end_month) == false)
				) {
					this.getRankingList();
					this.search_date_range = [];
				}
			}
		},
		getTopRanking() {
			this.$store
				.dispatch(GET, { url: `seo/${this.seoId}/project/${this.projectId}/seo-ranking-top-keyword` })
				.then((data) => {
					this.topRankingData = data ? { ...data } : {};
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		debounce() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.getRankingList();
			}, 600);
		},
		getRankingList() {
			this.pageLoading = true;
			const _this = this;
			const filter = {
				search: this.search_query,
				target_url: this.search_target_url,
				avg_search: "",
				per_page: null,
				current_page: null,
				daterange: this.search_date_range,
				start_month: this.start_month,
				end_month: this.end_month,
			};
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.$store
					.dispatch(QUERY, {
						url: `seo/${_this.seoId}/project/${_this.projectId}/seo-ranking-list`,
						data: filter,
					})
					.then((data) => {
						_this.thead = data.header;
						_this.tbody = data.data;
						const chartData = data.average_of_keyword;
						const chart_category = [];
						const chart_series = [];
						chartData.forEach((row) => {
							chart_category.push(row.current_month);
							chart_series.push(Math.ceil(+row.average_rank));
						});

						this.series[0].data = chart_series;
						this.chart_Options = chart_category;

						// const

						/* this.chartOptions = {
							...this.chartOptions,
							xaxis: {
								...this.chartOptions.xaxis,
								categories: chart_category,
							},
						}; */

						// console.log(this.chartOptions);
						// console.log(this.series);
					})
					.catch((error) => {
						console.log({ error });
					})
					.finally(() => {
						this.pageLoading = false;
					});
			}, 500);
		},
		getSeoData() {
			this.$store
				.dispatch(GET, { url: `getseocustomer?project_id=${this.projectId}&seo_id=${this.seoId}` })
				.then((data) => {
					/* let _data = cloneDeep({ ...data }); */
					this.seoData = cloneDeep({ ...data });
					/* for (const _key in _data) {
						let key_arr = Object.keys(_data[_key]).filter((v) => v.includes("top"));
						key_arr = _key == "current_rank" ? key_arr.concat(["keyword_SERP"]) : key_arr;
						key_arr.forEach((key) => {
							this.seoTotal[_key] += _data[_key][key];
						});
					} */
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
	computed: {
		onlyPositiveNums: {
			get() {
				return this.search_vol;
			},
			set(value) {
				this.toPositive(value);
			},
		},
		chart_Options: {
			get() {
				const _this = this;
				return {
					chart: {
						height: 350,
						type: "area",
						zoom: {
							enabled: false,
						},
						toolbar: {
							show: true,
							export: {
								filename: "average_position_graph",
								columnDelimiter: ",",
								headerCategory: "category",
								headerValue: "value",
								dateFormatter(timestamp) {
									return new Date(timestamp).toDateString();
								},
							},
						},
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						curve: "straight",
					},
					fill: {
						type: "gradient",
						gradient: {
							shadeIntensity: 1,
							opacityFrom: 0.5,
							opacityTo: 0.7,
							stops: [0, 90, 100],
						},
					},
					// title: {
					// 	text: "Product Trends by Month",
					// 	align: "left",
					// },
					grid: {
						row: {
							colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
							opacity: 0.5,
						},
					},
					xaxis: {
						categories: _this.categories,
						labels: {
							show: true,
							rotate: -45,
							formatter: function (value /*,timestamp , opts */) {
								return _this.MOMENTJS(value).format("DD/MM/YYYY");
							},
						},
						title: {
							text: "Dates",
						},
					},
					yaxis: {
						reversed: true,
						title: {
							text: "Average Position",
						},
					},
				};
			},
			set(array) {
				this.categories = [...array];
			},
		},
		tHead: {
			get() {
				const header = JSON.parse(JSON.stringify(this.thead));
				header.forEach((val, index) => {
					if (val.title.indexOf("-") > -1) {
						header[index].title = header[index]?.title.replaceAll("-", "/");
					}
				});
				return header;
			},
		},
	},
	mounted() {
		this.getRankingList();
		this.getTopRanking();
		this.getSeoData();
	},
};
</script>
